import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Alert, Box } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, email, password);
            alert('Logged in successfully');
        } catch (error) {
            setError('Failed to log in. Please check your email and password.');
        }
    };

    return (
        <Container>
            <Box mt={5}>
                <Typography variant="h4">Login</Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <form onSubmit={handleLogin}>
                    <TextField fullWidth margin="normal" label="Email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                    <TextField fullWidth margin="normal" label="Password" type="password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                    <Button fullWidth variant="contained" type="submit" color="primary">Login</Button>
                </form>
            </Box>
        </Container>
    );
}

export default Login;
