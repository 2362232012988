import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyC4GM-7EAZgWC2CXAcbbvjTr89Udu0cHZY",
  authDomain: "streamertunes.firebaseapp.com",
  projectId: "streamertunes",
  storageBucket: "streamertunes.firebasestorage.app",
  messagingSenderId: "1076658153537",
  appId: "1:1076658153537:web:cf6539a87ba3d972947ec0",
  measurementId: "G-32P1EER49L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

// Export the services
export { db, auth, functions };