import React, { useEffect, useState } from 'react';
import { Drawer, ListItem, ListItemText, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './Sidebar.css';

const drawerWidth = 240;

function Sidebar({ userId }) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        const userDocRef = doc(firestore, 'users', userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setIsAdmin(userData.admin || false);
          setUsername(userData.username || 'Guest');
        }
      }
    };
    fetchUserData();
  }, [userId, firestore]);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          background: 'linear-gradient(180deg, #1a1a1a, #0d0d0d)',
          color: 'white',
          borderRight: '1px solid rgba(230, 57, 70, 0.8)',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box>
        <ListItem button onClick={() => navigate('/')}>
          <ListItemText primary="Home Page" />
        </ListItem>
        <ListItem button onClick={() => navigate('/Tunes')}>
          <ListItemText primary="Streamer Tunes" />
        </ListItem>
        <ListItem button onClick={() => navigate(`/dashboard/${userId}`)}>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button onClick={() => navigate('/about')}>
          <ListItemText primary="About Us" />
        </ListItem>
        {isAdmin && (
          <ListItem button onClick={() => navigate('/admin')}>
            <ListItemText primary="Admin Dashboard" />
          </ListItem>
        )}
      </Box>
      
      <Box sx={{ mt: 'auto', p: 2 }}>
        {!userId ? (
          <Box>
            <ListItem button onClick={() => navigate('/login')}>
              <ListItemText primary="Login" sx={{ color: '#e63946', textAlign: 'center' }} />
            </ListItem>
            <ListItem button onClick={() => navigate('/signup')}>
              <ListItemText primary="Create Account" sx={{ color: '#e63946', textAlign: 'center' }} />
            </ListItem>
          </Box>
        ) : (
          <Typography variant="h6" sx={{ color: 'white', textAlign: 'center', mt: 2 }}>
            Welcome, {username || 'Guest'}!
          </Typography>
        )}
      </Box>
    </Drawer>
  );
}

export default Sidebar;
