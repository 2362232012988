import io from 'socket.io-client';

const socket = io('https://streamertunes-backend-b090fe9d479b.herokuapp.com', {
    withCredentials: true,
    transports: ['websocket'],
    extraHeaders: {
        "Access-Control-Allow-Origin": "https://streamertunes.org"
    }
});

export default socket;