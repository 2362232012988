import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Tabs, Tab, Paper, CircularProgress, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import VerifiedStreamers from './verifiedStreamers';
import './AllCreators.css';
import './verifiedStreamers.css';

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://streamertunes-backend.herokuapp.com' : 'http://localhost:3000';

const StreamerTunes = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const [creators, setCreators] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        if (tabValue === 0) {
            fetchVerifiedCreators(); // Fetch verified creators when "Verified Creators" tab is selected
        } else {
            fetchCreators(''); // Fetch all creators when "All Creators" tab is selected
        }
    }, [tabValue]);

    // Function to fetch all creators with optional search query
    const fetchCreators = async (query) => {
        setIsSearching(true);
        try {
            const response = await axios.post(`${BASE_URL}/api/optimized-search`, { filter: query });
            setCreators(response.data.result);
        } catch (error) {
            console.error('Error fetching creators:', error);
        } finally {
            setIsSearching(false);
        }
    };

    const fetchVerifiedCreators = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/verifiedStreamers`);
            setCreators(response.data);
        } catch (error) {
            console.error('Error fetching verified creators:', error);
        }
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        if (value.trim()) {
            fetchCreators(value.trim());
        } else {
            setCreators([]); // Clear creators if search is empty
        }
    };

    const handleTabChange = (event, newValue) => setTabValue(newValue);

    return (
        <Box>
            <Typography variant="h3">StreamerTunes</Typography>
            <TextField
                placeholder="Search creators..."
                onChange={handleSearchChange}
                value={searchTerm}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {isSearching && <CircularProgress size={20} />}
                        </InputAdornment>
                    ),
                }}
            />
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Verified Creators" />
                <Tab label="All Creators" />
            </Tabs>
            <Paper>
                {tabValue === 0 ? (
                    <VerifiedStreamers creators={creators} />
                ) : (
                    <Box className="creators-grid">
                        {creators.length > 0 ? (
                            creators.map((creator) => (
                                <div
                                    key={creator.username}
                                    className="creator-card"
                                    onClick={() => navigate(`/Tunes/${creator.username}`)}
                                >
                                    <img
                                        src={creator.imageUrlOptimized || 'default-profile.png'}
                                        alt={`${creator.username}'s profile`}
                                        className="profile-picture"
                                    />
                                    {creator.verified && (
                                        <div className="verified-badge">✓</div>
                                    )}
                                    <div className="creator-info">
                                        <h3>{creator.username}</h3>
                                        <p>Followers: {creator.followers}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            !isSearching && (
                                <Typography variant="body1" align="center">
                                    No creators found
                                </Typography>
                            )
                        )}
                    </Box>
                )}
            </Paper>
        </Box>
    );
};

export default StreamerTunes;
