import React, { useState, useEffect, useCallback } from 'react';
import { getFirestore, collection, doc, setDoc, getDocs, query, where } from 'firebase/firestore';
import { TextField, Button, Typography, Box, List, ListItem, Snackbar, Alert } from '@mui/material';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

const AdminDashboard = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [profilePicture, setProfilePicture] = useState('');
  const [verifiedCreators, setVerifiedCreators] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const firestore = getFirestore();
  const auth = getAuth();

  // Fetch all verified creators and update state
  const fetchVerifiedCreators = useCallback(async () => {
    const q = query(collection(firestore, 'users'), where('verified', '==', true));
    const querySnapshot = await getDocs(q);
    const creators = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setVerifiedCreators(creators);
  }, [firestore]);

  // Function to create verified creator account
  const handleCreateVerifiedCreator = async () => {
    if (!email || !password || !username) {
      setSnackbar({ open: true, message: "Please fill all fields", severity: "warning" });
      return;
    }

    try {
      // Step 1: Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Step 2: Store additional creator information in Firestore
      const newCreator = {
        username,
        email,
        profilePicture: profilePicture || '/default-profile.png',  // Default profile picture if none provided
        verified: true,
        createdAt: new Date(),
      };
      await setDoc(doc(firestore, 'users', user.uid), newCreator);

      // Fetch updated list of verified creators
      await fetchVerifiedCreators();

      // Clear input fields
      setUsername('');
      setEmail('');
      setPassword('');
      setProfilePicture('');
      setSnackbar({ open: true, message: "Verified Creator added successfully", severity: "success" });
    } catch (error) {
      console.error("Error creating verified creator:", error);
      setSnackbar({ open: true, message: "Failed to add creator. Try again.", severity: "error" });
    }
  };

  useEffect(() => {
    fetchVerifiedCreators();
  }, [fetchVerifiedCreators]);

  // Close snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box>
      <Typography variant="h4">Admin Dashboard</Typography>
      <TextField label="Username" value={username} onChange={(e) => setUsername(e.target.value)} fullWidth margin="normal" />
      <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth margin="normal" />
      <TextField label="Password" value={password} onChange={(e) => setPassword(e.target.value)} type="password" fullWidth margin="normal" />
      <TextField label="Profile Picture URL" value={profilePicture} onChange={(e) => setProfilePicture(e.target.value)} fullWidth margin="normal" />
      <Button onClick={handleCreateVerifiedCreator} variant="contained" color="primary" fullWidth>Add Verified Creator</Button>

      <Typography variant="h6" sx={{ mt: 4 }}>Verified Creators</Typography>
      <List>
        {verifiedCreators.map((creator) => (
          <ListItem key={creator.id}>
            {creator.username} - Verified
          </ListItem>
        ))}
      </List>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminDashboard;
