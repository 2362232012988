import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, TextField, Snackbar, Alert, Tabs, Tab, Card } from '@mui/material';
import { getFirestore, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getAuth, updatePassword } from 'firebase/auth';
import axios from 'axios';
import styles from './StreamerDashboard.module.css';

const BASE_URL = process.env.NODE_ENV === 'production'
    ? 'https://streamertunes-backend.herokuapp.com'
    : 'http://localhost:3000';

function StreamerDashboard() {
    const { userId } = useParams();
    const firestore = getFirestore();
    const auth = getAuth();

    const [tabValue, setTabValue] = useState(0);
    const [username, setUsername] = useState('');
    const [songRequestPrice, setSongRequestPrice] = useState(0);
    const [skipQueuePrice, setSkipQueuePrice] = useState(0);
    const [paypalEmail, setPaypalEmail] = useState('');
    const [balance, setBalance] = useState(0);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [profileImage, setProfileImage] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [songHistory, setSongHistory] = useState([]);

    const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

    useEffect(() => {
        if (!userId) return;

        const userDocRef = doc(firestore, `users/${userId}`);
        const unsubscribe = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                setUsername(data.profile?.username || '');
                setSongRequestPrice(data.settings?.songRequestPrice || 0);
                setSkipQueuePrice(data.settings?.skipQueuePrice || 0);
                setPaypalEmail(data.profile?.paypalEmail || '');
                setProfileImage(data.profile?.profileImage || '');
                setBalance(data.balance || 0);  // Get balance from Firestore
                setSongHistory(data.songHistory || []);
            } else {
                console.error("No such document found!");
            }
        });

        return () => unsubscribe();
    }, [firestore, userId]);

    const handleSavePricing = async () => {
        if (!userId) return;
        try {
            await updateDoc(doc(firestore, `users/${userId}/settings`), {
                songRequestPrice,
                skipQueuePrice
            });
            setSnackbar({ open: true, message: "Pricing updated successfully!", severity: "success" });
        } catch (error) {
            console.error("Error updating pricing:", error);
            setSnackbar({ open: true, message: "Failed to update pricing.", severity: "error" });
        }
    };

    const handleSavePaypalEmail = async () => {
        if (!userId || !paypalEmail) return;
        try {
            await updateDoc(doc(firestore, `users/${userId}/profile`), { paypalEmail });
            setSnackbar({ open: true, message: "PayPal email saved successfully!", severity: "success" });
        } catch (error) {
            console.error("Error saving PayPal email:", error);
            setSnackbar({ open: true, message: "Failed to save PayPal email.", severity: "error" });
        }
    };

    const handleSaveProfileImage = async () => {
        if (!userId || !profileImage) return;
        try {
            await updateDoc(doc(firestore, `users/${userId}/profile`), { profileImage });
            setSnackbar({ open: true, message: "Profile image updated successfully!", severity: "success" });
        } catch (error) {
            console.error("Error saving profile image:", error);
            setSnackbar({ open: true, message: "Failed to update profile image.", severity: "error" });
        }
    };

    const handleChangePassword = async () => {
        if (!newPassword) {
            setSnackbar({ open: true, message: "Please enter a new password.", severity: "warning" });
            return;
        }
        try {
            const user = auth.currentUser;
            await updatePassword(user, newPassword);
            setSnackbar({ open: true, message: "Password updated successfully!", severity: "success" });
            setNewPassword('');
        } catch (error) {
            console.error("Password update error:", error);
            setSnackbar({ open: true, message: "Failed to update password.", severity: "error" });
        }
    };

    const handleCashOut = async () => {
        try {
            await axios.post(`${BASE_URL}/api/cashout`, { userId, paypalEmail });
            setSnackbar({ open: true, message: "Cash-out successful!", severity: "success" });

            // Reset balance in Firestore
            await updateDoc(doc(firestore, `users/${userId}`), { balance: 0 });
            setBalance(0); // Update the local state to reflect balance reset
        } catch (error) {
            console.error("Cash-out error:", error);
            setSnackbar({ open: true, message: "Cash-out failed. Check PayPal settings.", severity: "error" });
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box className={styles.dashboardContainer}>
            <Typography variant="h4" className={styles.title}>
                {username ? `${username}'s Dashboard` : "Streamer's Dashboard"}
            </Typography>
            
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Profile" />
                <Tab label="Tunes" />
            </Tabs>

            {tabValue === 0 ? (
                <Box className={styles.section}>
                    <Typography className={styles.sectionHeader}>Profile</Typography>
                    
                    <TextField
                        className={styles.textField}
                        label="PayPal Email"
                        value={paypalEmail}
                        onChange={(e) => setPaypalEmail(e.target.value)}
                        helperText="Current PayPal Email"
                    />
                    <Button className={styles.button} onClick={handleSavePaypalEmail}>Save PayPal Email</Button>

                    <TextField
                        className={styles.textField}
                        label="Profile Image URL"
                        value={profileImage}
                        onChange={(e) => setProfileImage(e.target.value)}
                        helperText="Set your profile image URL"
                    />
                    <Button className={styles.button} onClick={handleSaveProfileImage}>Save Profile Image</Button>

                    <TextField
                        className={styles.textField}
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        helperText="Enter new password to update"
                    />
                    <Button className={styles.button} onClick={handleChangePassword}>Change Password</Button>
                </Box>
            ) : (
                <Box>
                    <Box className={styles.section}>
                        <Typography className={styles.sectionHeader}>Pricing Controls</Typography>
                        <TextField
                            className={styles.textField}
                            label="Song Request Price"
                            value={songRequestPrice}
                            onChange={(e) => setSongRequestPrice(Number(e.target.value))}
                        />
                        <TextField
                            className={styles.textField}
                            label="Skip Queue Price"
                            value={skipQueuePrice}
                            onChange={(e) => setSkipQueuePrice(Number(e.target.value))}
                        />
                        <Button className={styles.button} onClick={handleSavePricing}>Save Pricing</Button>
                    </Box>

                    <Box className={styles.section}>
                        <Typography className={styles.sectionHeader}>Balance: ${balance.toFixed(2)}</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCashOut}
                            disabled={!paypalEmail || balance <= 0}
                        >
                            Cash Out
                        </Button>
                    </Box>

                    <Box className={styles.section}>
                        <Typography className={styles.sectionHeader}>Song Request History</Typography>
                        <Card className={styles.card}>
                            {songHistory && songHistory.length > 0 ? (
                                songHistory.map((entry, index) => (
                                    <Box key={index} className={styles.historyEntry}>
                                        <Typography>
                                            {entry.songName} - Original: ${entry.amount} | Fee: ${(entry.amount * 0.3).toFixed(2)} | Net: ${(entry.amount * 0.7).toFixed(2)}
                                        </Typography>
                                    </Box>
                                ))
                            ) : (
                                <Typography>No song requests found</Typography>
                            )}
                        </Card>
                    </Box>
                </Box>
            )}

            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default StreamerDashboard;
