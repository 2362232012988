import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Alert, Box } from '@mui/material';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import defaultProfileImage from '../assets/default-profile.png';

function Signup() {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize the navigate hook

    const handleSignup = async (e) => {
        e.preventDefault();
        setError(null);

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const auth = getAuth();
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const db = getFirestore();
            // Use username as the document ID instead of UID
            await setDoc(doc(db, 'users', username), {
                uid: user.uid,
                profile: {
                    username,
                    email,
                    profileImage: defaultProfileImage, // default profile image
                    createdAt: serverTimestamp(),
                },
                settings: {
                    songRequestPrice: 0,
                    skipQueuePrice: 0,
                }
            });

            // Redirect to dashboard using the username as part of the path
            navigate(`/dashboard/${username}`);
        } catch (error) {
            console.error("Signup error:", error);
            setError('Failed to create account. Please try again.');
        }
    };

    return (
        <Container>
            <Box mt={5}>
                <Typography variant="h4">Sign Up</Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <form onSubmit={handleSignup}>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Username"
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Password"
                        type="password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Confirm Password"
                        type="password"
                        required
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button fullWidth variant="contained" type="submit" color="primary">
                        Sign Up
                    </Button>
                </form>
            </Box>
        </Container>
    );
}

export default Signup;
