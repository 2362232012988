import React, { useState, useEffect } from 'react'; // Updated import
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from './components/Sidebar';
import HomePage from './components/HomePage';
import AboutTunes from './components/AboutTunes';
import Signup from './components/Signup';
import Login from './components/Login';
import socket from './components/socket';
import StreamerDashboard from './components/StreamerDashboard';
import StreamerTunes from './components/StreamerTunes';
import AdminDashboard from './components/AdminDashboard';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

// Theme configuration
const theme = createTheme({
  palette: {
    primary: { main: '#e63946' },
    secondary: { main: '#252525' },
    background: {
      default: 'linear-gradient(135deg, #0d0d0d, #1a1a1a, #252525)',
      paper: 'rgba(29, 29, 29, 0.95)',
    },
    text: {
      primary: '#ffffff',
      secondary: '#e63946',
    },
  },
  typography: {
    fontFamily: `'Roboto', sans-serif`,
    h3: {
      fontWeight: 700,
      color: '#ffffff',
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
    },
    h4: {
      fontWeight: 600,
      color: '#e63946',
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
    },
    body1: {
      color: '#d4d4d4',
      lineHeight: 1.8,
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: 'linear-gradient(180deg, #1a1a1a, #0d0d0d)',
          color: 'white',
          borderRight: '1px solid rgba(230, 57, 70, 0.8)',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.7)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(145deg, #1a1a1a, #252525)',
          color: '#ffffff',
          borderRadius: '15px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.8)',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.06)',
            boxShadow: '0px 6px 25px rgba(230, 57, 70, 0.7)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          borderRadius: '50px',
          padding: '12px 35px',
          color: '#ffffff',
          background: 'linear-gradient(90deg, #e63946 0%, #ff616f 100%)',
          boxShadow: '0px 4px 15px rgba(230, 57, 70, 0.6)',
          transition: 'background 0.3s ease, box-shadow 0.3s ease',
          '&:hover': {
            backgroundColor: '#c72533',
            boxShadow: '0px 6px 20px rgba(230, 57, 70, 0.8)',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: '#1a1a1a',
          borderRadius: '8px',
          '& .MuiInputBase-input': {
            color: '#ffffff',
          },
          '& .MuiInputLabel-root': {
            color: '#e63946',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#e63946',
            },
            '&:hover fieldset': {
              borderColor: '#ff616f',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#e63946',
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          color: '#d4d4d4',
          '&.Mui-selected': {
            color: '#e63946',
          },
          textTransform: 'uppercase',
          transition: 'color 0.3s ease',
        },
        indicator: {
          backgroundColor: '#e63946',
          height: '3px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h3: {
          fontFamily: `'Poppins', sans-serif`,
          fontSize: '2rem',
          letterSpacing: '0.08em',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'rgba(20, 20, 20, 0.9)',
          backdropFilter: 'blur(10px)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(90deg, #252525, #1a1a1a)',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)',
        },
      },
    },
  },
});

function AppRoutes({ userId, isAdmin }) {
  return (
      <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutTunes />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard/:userId" element={<StreamerDashboard />} />
          <Route path="/tunes" element={<StreamerTunes />} />
          {isAdmin && <Route path="/admin" element={<AdminDashboard />} />} {/* Conditional Admin Route */}
      </Routes>
  );
}

function App() {
  const [userId, setUserID] = useState(null);
  const [username, setUsername] = useState('Guest');
  const [isAdmin, setIsAdmin] = useState(false); // Track admin status
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
          setLoading(false);
          if (user) {
              setUserID(user.uid);
              const userDocRef = doc(firestore, 'users', user.uid);
              const userDoc = await getDoc(userDocRef);
              
              if (userDoc.exists()) {
                  const userData = userDoc.data();
                  setUsername(userData.username || 'Guest');
                  setIsAdmin(userData.admin === true); // Check if user is admin
              }
          } else {
              setUsername('Guest');
              setIsAdmin(false);
          }
      });
      return () => unsubscribe();
  }, [auth, firestore]);

  useEffect(() => {
      socket.on('connect', () => {
          console.log('Connected to socket server');
      });

      socket.on('song_update', (data) => {
          console.log('Song update received:', data);
      });

      return () => {
          socket.off('connect');
          socket.off('song_update');
      };
  }, []);

  if (loading) {
      return <div>Loading...</div>;
  }

  return (
      <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
              <Box sx={{ display: 'flex' }}>
                  <Sidebar userId={userId} username={username} isAdmin={isAdmin} />
                  <Box component="main" sx={{ flexGrow: 1 }}>
                      <AppRoutes userId={userId} isAdmin={isAdmin} />
                  </Box>
              </Box>
          </Router>
      </ThemeProvider>
  );
}

export default App;