import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './verifiedStreamers.css';

const VerifiedStreamers = ({ creators }) => {
    const navigate = useNavigate();

    // Filter only verified creators
    const verifiedCreators = creators.filter((creator) => creator.verified);

    return (
        <Box className="creators-grid">
            {verifiedCreators.length > 0 ? (
                verifiedCreators.map((creator) => (
                    <div
                        key={creator.username}
                        className="creator-card"
                        onClick={() => navigate(`/Tunes/${creator.username}`)}
                    >
                        <img
                            src={creator.imageUrlOptimized || 'default-profile.png'}
                            alt={`${creator.username}'s profile`}
                            className="profile-picture"
                        />
                        {creator.verified && (
                            <div className="verified-badge">✓</div>
                        )}
                        <div className="creator-info">
                            <h3>{creator.username}</h3>
                            <p>Followers: {creator.followers}</p>
                        </div>
                    </div>
                ))
            ) : (
                <Typography variant="body1" align="center" className="no-creators">
                    No verified creators found
                </Typography>
            )}
        </Box>
    );
};

export default VerifiedStreamers;
