import React from 'react';

function AboutTunes() {
    return (
        <div>
            <h1>About StreamerTunes</h1>
            <p>StreamerTunes is a platform that allows viewers to request songs for streamers in real time. Our goal is to create an interactive and engaging environment for streamers and their fans.</p>
        </div>
    );
}

export default AboutTunes;
